import classnames from 'classnames';
import React, { useEffect } from 'react';
import { useLocalStorage } from 'usehooks-ts';

import { Button } from '@/design-system/atoms/Button';
import { ContentContainer } from '@/design-system/atoms/ContentContainer';
import { Grid, GridColumn } from '@/design-system/atoms/Grid';
import { LazyIconX } from '@/design-system/atoms/Icons/IconX/Lazy';
import { RichTextEditor } from '@/design-system/atoms/RichTextEditor';

import styles from './PrivacyBanner.module.scss';

const PrivacyBanner = ({ text, isInEditor, debugMode, ...props }) => {
    const [visible, setVisible] = useLocalStorage('privacy-banner-visible', true, {
        initializeWithValue: true,
    });
    const [isReady, setIsReady] = React.useState(false);
    const closeBanner = () => {
        setVisible(!visible);
    };
    const handleHyperlinkClick = () => {
        setVisible(!visible);
    };

    useEffect(() => {
        setIsReady(true);
    }, []);

    return (debugMode || visible) && isReady ? (
        <div
            className={classnames(
                styles['privacy-banner'],
                isInEditor ? '' : styles['privacy-banner--sticky'],
            )}
        >
            <ContentContainer className={styles[`privacy-banner__container`]}>
                <Grid className={styles[`privacy-banner__grid`]}>
                    <GridColumn colSpan={{ sm: 4, md: 6, lg: 9 }}>
                        <div className={styles[`privacy-banner__content`]}>
                            <RichTextEditor
                                text={text}
                                onAnchorClick={handleHyperlinkClick}
                                className={styles[`privacy-banner__richtext`]}
                            />
                        </div>
                        <Button
                            type="button"
                            ariaLabel={'Close Banner'}
                            buttonStyle={Button.STYLE.TERTIARY}
                            iconComponent={LazyIconX}
                            className={styles[`privacy-banner__close`]}
                            onClick={closeBanner}
                        />
                    </GridColumn>
                </Grid>
            </ContentContainer>
        </div>
    ) : (
        <></>
    );
};
export default PrivacyBanner;
